// FONTS
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');
@font-face {
  font-family: PlayfairDisplay;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.googleapis.com/css2?family=Playfair);
  src: url(https://fonts.googleapis.com/css2?family=Playfair)
      format('embedded-opentype'),
    url('../assets/fonts/PlayfairDisplay-Regular.ttf')
      format('ttf');
}

@font-face {
  font-family: PlayfairDisplaySemiBold;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.googleapis.com/css2?family=Playfair);
  src: url(https://fonts.googleapis.com/css2?family=Playfair)
      format('embedded-opentype'),
    url('../assets/fonts/PlayfairDisplay-SemiBold.ttf');
}

@font-face {
  font-family: RobotoLight;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.googleapis.com/css2?family=RobotoMono);
  src: url(https://fonts.googleapis.com/css2?family=RobotoMono)
      format('embedded-opentype'),
    url('../assets/fonts/RobotoMono-Light.ttf')
      format('ttf');
}

@font-face {
  font-family: RobotoRegular;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.googleapis.com/css2?family=RobotoMono);
  src: url(https://fonts.googleapis.com/css2?family=RobotoMono)
      format('embedded-opentype'),
    url('../assets/fonts/RobotoMono-Regular.ttf')
      format('ttf');
}

@font-face {
  font-family: PlayfairDisplayMedium;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.googleapis.com/css2?family=Playfair);
  src: url(https://fonts.googleapis.com/css2?family=Playfair)
      format('embedded-opentype'),
    url('../assets/fonts/PlayfairDisplay-Medium.ttf')
      format('ttf');
}

@font-face {
  font-family: RobotoMedium;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.googleapis.com/css2?family=RobotoMono);
  src: url(https://fonts.googleapis.com/css2?family=RobotoMono)
      format('embedded-opentype'),
    url('../assets/fonts/RobotoMono-Medium.ttf')
      format('ttf');
}
