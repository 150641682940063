ion-searchbar ion-icon {
  right: 16px !important;
  left: auto !important;
}

.searchbar-input.sc-ion-searchbar-md {
  padding-left: 15px !important;
  padding-right: unset !important;
  -webkit-padding-start: 15px !important;
  padding-inline-start: 15px !important;
  -webkit-padding-end: 15px !important;
  padding-inline-end: 15px !important;
}

.searchbar-input-container {
  .searchbar-input {
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-family: 'Roboto Medium', sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.43;
      letter-spacing: normal;
      color: var(--inactive-icon);
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      font-family: 'Roboto Medium', sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.43;
      letter-spacing: normal;
      color: var(--inactive-icon);
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      font-family: 'Roboto Medium', sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.43;
      letter-spacing: normal;
      color: var(--inactive-icon);
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      font-family: 'Roboto Medium', sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.43;
      letter-spacing: normal;
      color: var(--inactive-icon);
    }
  }

  .searchbar-clear-icon {
    filter: invert(100);
  }
}
.my-custom-modal-css .modal-wrapper {
  height: 60%;
  top: 40%;
  position: absolute;
  display: block;
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
}
.modal-bed .modal-wrapper {
  height: 35%;
}

.custom {
  &-accept {
    border-radius: 4px !important;
    background-color: #145cbf !important;
    color: white !important;
    text-transform: capitalize !important;
    width: 35% !important;
    height: 35px !important;
    span {
      justify-content: center !important;
    }
  }
  &-cancel {
    border-radius: 4px !important;
    background-color: #f45f40 !important;
    color: white !important;
    text-transform: capitalize !important;
    width: 35% !important;
    height: 35px !important;
    span {
      justify-content: center !important;
    }
  }
}
.alert-button-group.sc-ion-alert-md {
  justify-content: center;
}
.alert-head.sc-ion-alert-md {
  text-align: center;
}
.alert-input-group {
  display: flex;
  gap: 10px;
}
.alert-input-wrapper.sc-ion-alert-md {
  width: 50%;
  &:first-child {
    &::before {
      content: 'Horas';
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #162b3d;
    }
  }
  &:last-child {
    &::before {
      content: 'Mins';
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #162b3d;
    }
  }
}

/* SCCS alternative */
.mat-calendar-body-active {
  .mat-calendar-body-today {
    color: white;
    background-color: #145cbf;
  }
}

/* CSS alternative */
.mat-calendar-body-active > .mat-calendar-body-today {
  color: white;
  background-color: #145cbf;
}

.mat-calendar-body-cell:not(
    .mat-calendar-body-disabled
  ):hover
  > .mat-calendar-body-cell-content:not(
    .mat-calendar-body-selected
  ):not(.mat-calendar-body-comparison-identical) {
  border: solid 0.5px #f45f40;
}
ion-card-title {
  --color: #000000;
}
