.movsa-1\/2 {
  width: 50%;
  float: left;
}
.movsa-1\/3 {
  width: 33%;
  float: left;
}
.movsa-2\/3 {
  width: 66%;
  float: left;
}

.movsa-content--height {
  min-height: calc(100vh - 155px);
}
