@import 'variables';
@import 'colors';
@import 'fonts';
@import 'sizes';
@import 'buttons';
@import 'ionic-styles';
:root {
  --warm-grey: #707070;
  --white: #ffffff;
  --black: #000000;
  --dark-blue-grey: #162b3d;
}
