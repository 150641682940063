@import 'fonts';

.primaryButton {
  cursor: pointer;
  width: 322px;
  height: 60px;
  border-radius: 20px;
  background-color: #0097c2;
  color: #202020;
  font-size: 16px;
  font-family: 'Roboto Medium', sans-serif;
  line-height: 24px;
  border: none;
  &:hover {
    box-shadow: 0 0 10px 0 #0097c2;
  }
  &:focus {
    background-color: #047291;
  }
  &:disabled,
  &[disabled] {
    background-color: rgba(0 151 194 / 57%);
    box-shadow: none;
    cursor: auto;
  }
}

.secondaryButton {
  cursor: pointer;
  width: 322px;
  height: 60px;
  border-radius: 20px;
  background-color: #fbfffb;
  border: 1px solid #0097c2;
  color: #202020;
  font-size: 16px;
  font-family: 'Roboto Medium', sans-serif;
  line-height: 24px;
  &:hover {
    box-shadow: 0 0 10px 0 #0097c2;
  }
  &:focus {
    background-color: #0097c2;
  }
  &:disabled,
  &[disabled] {
    background-color: #ffffff;
    border: 1px solid rgba(0 151 194 / 57%);
    box-shadow: none;
    cursor: auto;
  }
}

.tertiaryButton {
  cursor: pointer;
  text-decoration: none;
  color: #0097c2;
  font-size: 16px;
  font-family: 'Roboto Medium', sans-serif;
  line-height: 24px;
  &:hover {
    color: #0097c2;
  }
  &:focus {
    color: #0097c2;
  }
  &:disabled,
  &[disabled] {
    color: #fbfffb;
  }
}

button,
textarea,
select {
  outline: none;
}
